<template>
  <div class="bg-grey">
    <ApplicantHeader />
    <div class="container-fluid profile-user px-1 px-md-3 pt-4 pb-5">
      <div class="col-md-12 box">
        <div class="profile-flex border-bottom py-3">
          <h4 class="heading">Your Account</h4>
          <button class="btn btn-primary btn-crud" @click="$router.push({ name: 'Applicant-Onboarding-1' })">
            <img src="@/assets/app-icons/edit.svg" width="15px" />
            Edit Details
          </button>
        </div>
        <ValidationObserver ref="changePass" tag="div">
          <div class="border-bottom py-3">
            <b-form class="customized-form">
              <b-form-input :disabled="true" placeholder="E-mail" v-model="user.email" class="col-md-3"> </b-form-input>
              <div class="account-grid">
                <ValidationProvider name="password" rules="required" vid="confirmation" tag="div" v-slot="{ errors, classes }">
                  <b-form-input type="password" placeholder="Change Password" v-model="user.password" :class="classes" class="form-control"> </b-form-input>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider tag="div" rules="required|confirmed:confirmation" v-slot="{ errors, classes }">
                  <b-form-input :class="classes" placeholder="Confirm Password" type="password" v-model="confirmPassword" class="form-control"> </b-form-input>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>

                <button class="btn btn-primary btn-custom" @click.prevent.stop="changePassword">Change Password</button>
              </div>
            </b-form>
          </div>
        </ValidationObserver>
        <!-- COMMENTED FOR FREE VERSION -->
        <!-- <div>
          <h4 class="heading py-3">Your Subscription</h4>
          <b-tabs
            pills
            align="right"
            class="pills-primary pricing-tabs mt-md-n5"
          >
            <b-tab :active="activesub == 'Yearly'" title="YEARLY">
              <slick
                ref="yearlySlick"
                :options="yearlySlider"
                v-if="yearlySubs.length > 0"
                class="pricing-slick"
              >
                <div class="" v-for="(subs, index) in yearlySubs" :key="index">
                  <div class="pricing-card">
                    <div class="pricing-header">
                      <h5 class="pricing-title">{{ subs.subs_pkg_title }}</h5>
                      <div class="pricing-status">
                        <h4
                          class="pricing-cost"
                          v-if="subs.subs_pkg_annually_price"
                        >
                          ${{ (subs.subs_pkg_annually_price / 12).toFixed(2) }}
                          per month
                        </h4>
                        <h6
                          class="pricing-plan"
                          v-if="subs.subs_pkg_annually_price"
                        >
                          paid annually
                        </h6>
                      </div>
                    </div>
                    <div class="pricing-content">
                      <ul class="pricing-content-list mb-0">
                        <li v-if="subs.subs_pkg_no_of_videos != 0">
                          {{ subs.subs_pkg_no_of_videos }} Videos
                        </li>
                        <li v-if="subs.subs_pkg_no_of_videos == 0">
                          No Videos
                        </li>
                        <li>{{ subs.subs_pkg_no_of_applicants }}</li>
                        <li>{{ subs.subs_pkg_features }}</li>
                        <li>{{ subs.subs_pkg_no_of_cvs }}</li>
                      </ul>
                      <div
                        v-if="
                          selectedSub &&
                            selectedSub.subs_pkg_id == subs.subs_pkg_id
                        "
                        style="color: #b1b1b1"
                      >
                        Valid till
                        {{
                          dateFormat(
                            selectedSub.subscription_end_date_timestamp
                          )
                        }}
                      </div>
                    </div>
                    <div class="pricing-footer mb-n2">
                      <button
                        v-if="
                          selectedSub &&
                            selectedSub.subs_pkg_id == subs.subs_pkg_id
                        "
                        :class="{
                          'btn btn-primary pricing-btn':
                            selectedSub.subs_pkg_id != subs.subs_pkg_id,
                          'btn pricing-active':
                            selectedSub.subs_pkg_id == subs.subs_pkg_id,
                        }"
                      >
                        ACTIVE
                      </button>
                      <button
                        v-if="
                          selectedSub &&
                            selectedSub.subs_pkg_id == subs.subs_pkg_id
                        "
                        class="btn btn-danger mt-2"
                        @click="deactivePlan(subs.subs_pkg_id)"
                      >
                        DE-ACTIVATE
                      </button>
                      <button
                        v-else
                        :class="{
                          'btn btn-primary pricing-btn':
                            selectedSub.subs_pkg_id != subs.subs_pkg_id,
                          'btn pricing-active':
                            selectedSub.subs_pkg_id == subs.subs_pkg_id,
                        }"
                        @click="createOrder(subs.subs_pkg_id)"
                      >
                        {{
                          subs.subs_pkg_title == "FREEE" ||
                          subs.subs_pkg_title == "Free"
                            ? "SELECT"
                            : "PAY NOW"
                        }}
                      </button>
                    </div>
                  </div>
                </div>
              </slick>
            </b-tab>
            <b-tab :active="activesub == 'Monthly'" title="MONTHLY">
              <slick
                ref="monthlySlick"
                :options="monthlySlider"
                v-if="monthlySubs.length"
                class="pricing-slick"
              >
                <div class="" v-for="(subs, index) in monthlySubs" :key="index">
                  <div class="pricing-card">
                    <div class="pricing-header">
                      <h5 class="pricing-title">{{ subs.subs_pkg_title }}</h5>
                      <div class="pricing-status">
                        <h4
                          class="pricing-cost"
                          v-if="subs.subs_pkg_monthly_price"
                        >
                          ${{ subs.subs_pkg_monthly_price }} per month
                        </h4>
                        <h6
                          class="pricing-plan"
                          v-if="subs.subs_pkg_monthly_price"
                        >
                          paid monthly
                        </h6>
                      </div>
                    </div>
                    <div class="pricing-content">
                      <ul class="pricing-content-list mb-0">
                        <li v-if="subs.subs_pkg_no_of_videos != 0">
                          {{ subs.subs_pkg_no_of_videos }} Videos
                        </li>
                        <li v-if="subs.subs_pkg_no_of_videos == 0">
                          No Videos
                        </li>
                        <li>{{ subs.subs_pkg_no_of_applicants }}</li>
                        <li>{{ subs.subs_pkg_features }}</li>
                        <li>{{ subs.subs_pkg_no_of_cvs }}</li>
                      </ul>
                      <div
                        v-if="
                          selectedSub &&
                            selectedSub.subs_pkg_id == subs.subs_pkg_id
                        "
                        style="color: #b1b1b1"
                      >
                        Valid till
                        {{
                          dateFormat(
                            selectedSub.subscription_end_date_timestamp
                          )
                        }}
                      </div>
                    </div>
                    <div class="pricing-footer mb-n2">
                      <button
                        v-if="
                          selectedSub &&
                            selectedSub.subs_pkg_id == subs.subs_pkg_id
                        "
                        :class="{
                          'btn btn-primary pricing-btn':
                            selectedSub.subs_pkg_id != subs.subs_pkg_id,
                          'btn pricing-active':
                            selectedSub.subs_pkg_id == subs.subs_pkg_id,
                        }"
                      >
                        ACTIVE
                      </button>
                      <button
                        v-if="
                          selectedSub &&
                            selectedSub.subs_pkg_id == subs.subs_pkg_id
                        "
                        class="btn btn-danger mt-2"
                        @click="deactivePlan(subs.subs_pkg_id)"
                      >
                        DE-ACTIVATE
                      </button>
                      <button
                        v-else
                        :class="{
                          'btn btn-primary pricing-btn':
                            selectedSub.subs_pkg_id != subs.subs_pkg_id,
                          'btn pricing-active':
                            selectedSub.subs_pkg_id == subs.subs_pkg_id,
                        }"
                        @click="createOrder(subs.subs_pkg_id)"
                      >
                        {{
                          subs.subs_pkg_title == "FREEE" ||
                          subs.subs_pkg_title == "Free"
                            ? "SELECT"
                            : "PAY NOW"
                        }}
                      </button>
                    </div>
                  </div>
                </div>
              </slick>
            </b-tab>
          </b-tabs>
        </div> -->
        <!-- COMMENTED FOR FREE VERSION END -->

        <!-- <div class="py-3 row border-top">
          <div class="col-md-4">
            <h4 class="heading">Notifications</h4>
            <div class="flex-between py-2">
              <span>New Job Notification</span>
              <b-form-checkbox
                v-model="alert[0].status"
                class=""
                size="lg"
                @change="addAlert"
                switch
              >
              </b-form-checkbox>
            </div>
            <div class="flex-between py-2">
              <span>Shortlist Alert</span>
              <b-form-checkbox
                class=""
                size="lg"
                v-model="alert[1].status"
                @change="addAlert"
                switch
              >
              </b-form-checkbox>
            </div>
            <div class="flex-between py-2">
              <span>Application status alert</span>
              <b-form-checkbox
                class=""
                v-model="alert[2].status"
                @change="addAlert"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
            <div class="flex-between py-2">
              <span>Deadline alert</span>
              <b-form-checkbox
                class=""
                v-model="alert[3].status"
                @change="addAlert"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
          <div class="offset-md-2 col-md-4">
            <h4 class="heading">Job search status</h4>
            <div class="flex-between py-2">
              <span>Actively Looking</span>
              <b-form-checkbox
                class=""
                v-model="alert[4].status"
                @change="addAlert"
                size="lg"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <b-modal ref="welcome-applicant" hide-header hide-footer size="lg" centered>
      <div class="text-center">
        <h2>Welcome</h2>
        <h2>Applicant</h2>
      </div>
    </b-modal>
    <b-modal id="myModel" hide-header hide-footer>
      <div class="text-center">
        <b-spinner label="Loading..." style="width: 8rem; height: 8rem"> </b-spinner>
        <h2>Uploading...</h2>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApplicantHeader from "@/views/Applicant-Profile-Screens/Applicant.vue";
import ApplicantProfileService from "@/apiServices/ApplicantProfileService.js";
import UserService from "@/apiServices/UserService.js";
import AlertSetting from "@/apiServices/AlertSetting.js";

import CheckoutServices from "@/apiServices/CheckoutServices";

import moment from "moment";

export default {
  components: {
    ApplicantHeader,
  },
  data() {
    return {
      activesub: null,
      subscriptions: [],
      monthlySubs: [],
      videos: [],
      loader: null,
      loader2: null,
      yearlySubs: [],
      selectedSub: {},
      user: { email: null, password: null },
      confirmPassword: "",

      alert: [
        { user_alert_name: "New Job Notification", status: false },
        { user_alert_name: "Shortlist Alert", status: false },
        { user_alert_name: "Applicantion Status Result", status: false },
        { user_alert_name: "Deadline Alert", status: false },
        { user_alert_name: "Actively Looking", status: false },
      ],

      yearlySlider: {
        arrows: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
        ],
      },
      monthlySlider: {
        arrows: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
        ],
      },
    };
  },
  beforeMount() {
    this.getUser();
    //DISABLED FOR FREE VERSION
    // this.getAllVideos();
    //DISABLED FOR FREE VERSION END
  },
  mounted() {
    // if (this.$route.query.login && this.$route.query.login == 1) {
    //   this.showModal();
    //   setTimeout(() => {
    //     this.hideModal();
    //   }, 2000);
    // }
    //DISABLED FOR FREE VERSION
    // this.getSubscription();
    //DISABLED FOR FREE VERSION END
    // this.getSelectedSubscription();
    //this.getAlertSettings();
  },

  methods: {
    dateFormat(date) {
      return moment.unix(date).format("YYYY-MM-DD");
    },
    getUser() {
      UserService.getRegisteredUser().then((res) => {
        
        this.user.email = res.data.data.user_email;
      });
    },
    async getAllVideos() {
      const payload = { user_id: this.$cookies.get("user_id") };
      try {
        const getVideos = await ApplicantProfileService.GetUploadedVideos(payload);
        this.videos = getVideos.data.data;
      } catch (error) {
        
      }
    },
    async createOrder(subs_pkg_id) {
      let [selectSubs] = this.yearlySubs.filter((f) => {
        if (f.subs_pkg_id == subs_pkg_id) {
          return true;
        }
      });
      if (selectSubs == undefined) {
        [selectSubs] = this.monthlySubs.filter((f) => {
          if (f.subs_pkg_id == subs_pkg_id) {
            return true;
          }
        });
      }
      console.log("selectSubs :>> ", selectSubs);
      console.log("this.videos.length :>> ", this.videos.length);
      console.log("this.videos.length > +selectSubs.subs_pkg_no_of_videos :>> ", this.videos.length > +selectSubs.subs_pkg_no_of_videos);
      if (this.videos.length > +selectSubs.subs_pkg_no_of_videos) {
        let dif = this.videos.length - +selectSubs.subs_pkg_no_of_videos;
        this.$toasted.show(`You have ${dif} extra videos for this plan. Delete them to select this plan`, { duration: 6000, icon: "clear" });
        return;
      }

      this.loader2 = this.$loading.show();
      try {
        let response = await CheckoutServices.CreateOrder({
          subs_pkg_id,
          redirect_flag: 2,
        });
        // loader.hide();
        let { data } = response;
        if (data.data.type == "paid") {
          window.location.href = data.data.payment.links[1].href;
        } else if (data.data.type == "free") {
          //loader.hide();
          this.getSelectedSubscription();
          this.$toasted.show("Subscription Updated!", {
            duration: 6000,
            icon: "check",
          });
        } else {
          this.loader2.hide();
          console.log("data.data :>> ", data.data);
          if (!data.data.status) {
            this.$toasted.show("Invalid Subscription. Delete Videos first", {
              duration: 6000,
              icon: "clear",
            });
          } else {
            this.loader2.hide();
            this.$toasted.show("Contact Us for more information", {
              duration: 6000,
              icon: "clear",
            });
          }
        }
      } catch (error) {
        this.loader2.hide();
        
        this.$toasted.show("Payment Failed, try again later", {
          duration: 6000,
          icon: "clear",
        });
      }
    },
    showModal() {
      this.$refs["welcome-applicant"].show();
    },
    hideModal() {
      console.log("in hide :>> ");
      this.$refs["welcome-applicant"] ? this.$refs["welcome-applicant"].hide() : null;
    },
    getSubscription() {
      this.loader = this.$loading.show();
      ApplicantProfileService.getSubscription()
        .then((sub) => {
          console.log("sub.data.data :>> ", sub.data.data);
          sub.data.data.forEach((s) => {
            if (s.subscription_type_id == 1) {
              this.monthlySubs.push(s);
            } else {
              this.yearlySubs.push(s);
            }
          });
          // this.monthlySubs = sub.data.data.filter((f) => {
          //   if (f.subscription_type_id == 1) {
          //     return true;
          //   }
          // });
          // this.yearlySubs = sub.data.data.filter((f) => {
          //   if (f.subscription_type_id == 2) {
          //     return true;
          //   }
          // });

          console.log("this.subscriptions :>> ", this.subscriptions);
          // this.loader.hide();
          this.getSelectedSubscription();
        })
        .catch((err) => {
          
          if (this.loader) this.loader.hide();
        });
    },
    getAlertSettings() {
      const user_id = this.$cookies.get("user_id");
      const role_id = this.$cookies.get("role_id");

      AlertSetting.GetAlertSetting({ user_id, role_id })
        .then((res) => {
          
          if (res.data.data.length > 0) {
            this.alert = res.data.data.map((m) => {
              return {
                user_alert_name: m.user_alert_name,
                status: m.user_alert_status == "Y" ? true : false,
              };
            });
          }
          if (this.loader) this.loader.hide();
          if (this.loader2) this.loader2.hide();
        })
        .catch((err) => {
          
          if (this.loader) this.loader.hide();
          if (this.loader2) this.loader2.hide();
        });
    },
    addAlert() {
      const user_id = this.$cookies.get("user_id");
      const role_id = this.$cookies.get("role_id");
      const payload = { user_id, role_id, alert: this.alert };
      AlertSetting.AddAlertSetting(payload)
        .then((res) => {
          
        })
        .catch((err) => {
          
        });
    },
    getSelectedSubscription() {
      const user_id = this.$cookies.get("user_id");
      ApplicantProfileService.getUserSubscription(user_id)
        .then((res) => {
          //this.loader.hide();
          console.log("this.selectedsubs", res.data.data[0]);
          if (res.data.data.length > 0) {
            this.selectedSub = res.data.data[0];
            this.selectedSub.subscription_type_id == 1 ? (this.activesub = "Monthly") : (this.activesub = "Yearly");
          } else {
            this.selectedSub = [];
          }
          this.getAlertSettings();
        })
        .catch((err) => {
          
          if (this.loader) this.loader.hide();
          if (this.loader2) {
            this.loader2.hide();
          }
        });
    },
    deactivePlan(subs_pkg_id) {
      this.$bvModal
        .msgBoxConfirm("Do You Want to De-Activate this plan?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            CheckoutServices.DeActivatePlan({ subs_pkg_id })
              .then((res) => {
                
                this.$toasted.show("Plan De-Activated", {
                  duration: 6000,
                  icon: "check",
                });
                this.getSelectedSubscription();
              })
              .catch((err) => {
                
              });
          } else {
            return;
          }
        });
    },
    ValidatePassword() {
      let password = this.user.password;
      let confirmPass = this.confirmPassword;
      validate(changePassword, "required|confirmed:@confirmation", {
        name: "Password",
        values: {
          confirmPass,
        },
      })
        .then((result) => {
          console.log("result :>> ", result);
          if (result.valid) {
            return true;
          } else {
            return false;
          }
        })
        .catch((err) => {
          
        });
    },

    changePassword() {
      this.$refs.changePass.validate().then((result) => {
        if (result) {
          const payload = {
            ...this.user,
            user_id: this.$cookies.get("user_id"),
          };
          UserService.updatePassword(payload).then((res) => {
            
            this.user = { email: "", password: "" };
            this.confirmPassword = "";
            this.$toasted.show("Your credentials updated successfully!", {
              duration: 6000,
              icon: "check",
            });
          });
        } else {
          this.$toasted.show("Enter Correct Inputs!", {
            duration: 6000,
            icon: "clear",
          });
        }
      });
    },
    selectSub(sub_id) {
      const payload = {
        user_id: this.$cookies.get("user_id"),
        subs_pkg_id: sub_id,
      };
      
      ApplicantProfileService.updateUserSubscription(payload)
        .then((res) => {
          
          this.getSelectedSubscription();
          this.$toasted.show("Subscription Updated!", {
            duration: 6000,
            icon: "check",
          });
        })
        .catch((err) => {
          
          this.$toasted.show("Subscription not updated!", {
            duration: 6000,
            icon: "clear",
          });
        });
    },
  },
};
</script>

<style scoped>
.btn-crud-1 {
  font-family: mp-medium;
  font-size: 12px;
  border-radius: 20px;
  padding: 08px 22px;
  background: #6244e5 !important;
  color: white;
  border: 1px solid #6244e5;
  margin-bottom: 0;
}

.pricing-tabs .tab-content > .tab-pane:not(.active) {
  display: block !important;
  height: 0;
  overflow-y: hidden;
}
</style>
